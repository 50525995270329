//библиотека управления состоянием
import { createStore } from 'vuex';

//функция принимает jwt токен
function parseJwt(token) {
    //делит токен по точкам и берет payload
    const base64Url = token.split('.')[1];
    //замена символов - на + и _ на /
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //преобразует в читаемый json
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    //преобразует json в объект js
    return JSON.parse(jsonPayload);
}

//экземпляр хранилища vuex
const store = createStore({
    //начальное состояние хранилища
    state: {
        //хранения данных аутентификации пользователя
        auth: {
            accessToken: '',
            refreshToken: '',
            expiry: '',
            username: '',
            userRole: ''
        }
    },
    mutations: { //мутации - синхронные функции, изменяющие состояние хранилища
        //обновляет состояние auth в соответсвии с данными из authData
        setAuth(state, authData) {
            //присвоение соответствующим полям состояния auth в store, если authData содержит новые значения accessToken, refreshToken, expiry
            if (authData.accessToken) state.auth.accessToken = authData.accessToken;
            if (authData.refreshToken) state.auth.refreshToken = authData.refreshToken;
            if (authData.expiry) state.auth.expiry = authData.expiry;
        },
        //очищает данные аутентификации пользователя
        clearAuth(state) {
            state.auth.accessToken = '';
            state.auth.refreshToken = '';
            state.auth.expiry = '';
            state.auth.userRole = '';
            state.auth.username = '';
        },
        //устанавливает имя пользователя и роль в состоянии аутентификации
        setUser(state, userData) {
            state.auth.username = userData.username;
            state.auth.userRole = userData.role;
        },
        //загружает сохраненное состояние аутентификации из локального хранилища и обновляет текущее состояние
        restoreState(state) {
            const stateFromStorage = localStorage.getItem('authState');
            if (stateFromStorage) {
                Object.assign(state.auth, JSON.parse(stateFromStorage));
            }
        }
    },
    actions: {
        //обновляет состояние аутентификации, разбирает токен JWT для получения данных пользователя и сохраняет состояние
        login({ commit }, authData) {
            commit('setAuth', authData);
            const userData = parseJwt(authData.accessToken);
            commit('setUser', { username: userData.unique_name, role: userData.role });
            this.dispatch('saveState');
        },
        //очищает состояние аутентификации и удаляет его из локального хранилища
        logout({ commit }) {
            commit('clearAuth');
            localStorage.removeItem('authState');
            this.dispatch('saveState');
        },
        //сохраняет текущее состояние аутентификации в локальное хранилище
        saveState({ state }) {
            const stateToSave = JSON.stringify(state.auth); //преобразование объекта состояния авторизации в строку json
            localStorage.setItem('authState', stateToSave); //сохранение строки состояния в localStorage под ключом authState
        },
        //обновляет токены в состоянии и сохраняет его
        updateTokens({ commit }, tokens) {
            commit('setAuth', tokens); //установка новых токенов авторизации
            localStorage.setItem('refreshToken', tokens.refreshToken); //сохранение обновленного рефреш токена в localStorage под ключом refreshToken
            this.dispatch('saveState'); //сохранение текущего состояния хранилища
        },
        //добавление слушателя событий localStorage
        initializeListeners({ commit }) {
            window.addEventListener('storage', (event) => {
                if (event.key === 'refreshToken') {
                    commit('setAuth', { refreshToken: event.newValue });
                }
                if (event.key === 'authState') {
                    commit('restoreState');
                }
            });
        },
    },
    //позволяют получить доступ к состояниям: аутентификация, имя пользователя и т.д.
    getters: {
        isAuthenticated: (state) => {
            return !!state.auth.accessToken;
        },
        username: (state) => {
            return state.auth.username;
        },
        userRole: (state) => {
            return state.auth.userRole;
        },
        accessToken: (state) => {
            return state.auth.accessToken;
        },
        refreshToken: (state) => {
            return state.auth.refreshToken;
        },
        expiry: (state) => {
            return state.auth.expiry;
        }
    }
});
//инициализация слушателей сразу после создания store
store.dispatch('initializeListeners');

//вызов мутации restoreState после создания хранилища, чтобы восстановить состояние аутентификации из локального хранилища
store.commit('restoreState');

export default store;

<template>
	<VaLayout style="height: 100vh" :left="{ absolute: breakpoints.smDown }">
		<template #top>
			<VaNavbar color="primary" class="py-3">
				<template #left>
					<VaButton :icon="showSidebar ? 'menu_open' : 'menu'" @click="showSidebar = !showSidebar" />
				</template>
				<template #center>
					<VaNavbarItem class="va-h6">
						Управление франчайзи
					</VaNavbarItem>
				</template>
				<template #right>
					<div style="display: flex; align-items: center;">
						<span class="mr-3">{{ userName }}</span>
						<LogoutButton></LogoutButton>
					</div>
				</template>
			</VaNavbar>
		</template>

		<template #left>
			<VaSidebar v-model="showSidebar" width="15rem">
				<VaSidebarItem :class="{ active: isActive('/users') }" v-if="isAdministrator" @click="navigateTo('/users')">
					<VaSidebarItemContent>
						<VaIcon name="person" />
						<VaSidebarItemTitle>
							Пользователи
						</VaSidebarItemTitle>
					</VaSidebarItemContent>
				</VaSidebarItem>
				<VaSidebarItem :class="{ active: isActive('/roles') }" v-if="isAdministrator" @click="navigateTo('/roles')">
					<VaSidebarItemContent>
						<VaIcon name="group" />
						<VaSidebarItemTitle>
							Роли
						</VaSidebarItemTitle>
					</VaSidebarItemContent>
				</VaSidebarItem>
				<VaSidebarItem :class="{ active: isActive('/books') }" @click="navigateTo('/books')">
					<VaSidebarItemContent>
						<VaIcon name="menu_book" />
						<VaSidebarItemTitle>
							Учебники
						</VaSidebarItemTitle>
					</VaSidebarItemContent>
				</VaSidebarItem>
				<VaSidebarItem :class="{ active: isActive('/franchisee') }" @click="navigateTo('/franchisee')">
					<VaSidebarItemContent>
						<VaIcon name="apartment" />
						<VaSidebarItemTitle>
							Франчайзи
						</VaSidebarItemTitle>
					</VaSidebarItemContent>
				</VaSidebarItem>
				<VaSidebarItem :class="{ active: isActive('/journal') }" v-if="isAdministrator" @click="navigateTo('/journal')">
					<VaSidebarItemContent>
						<VaIcon name="preview" />
						<VaSidebarItemTitle>
							Журнал действий
						</VaSidebarItemTitle>
					</VaSidebarItemContent>
				</VaSidebarItem>
			</VaSidebar>
		</template>

		<template #content>
			<main class="p-3">
				<router-view></router-view>
			</main>
		</template>
	</VaLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useBreakpoint } from 'vuestic-ui';
import LogoutButton from '@/components/LogoutButton.vue';
import store from '../store'

export default {
	components: {
		LogoutButton
	},
	computed: {
		currentTab() {
			return this.tabs.find(({ title }) => title === this.value);
		},
		isAdministrator() {
			const userRole = this.$store.getters.userRole;
			return userRole === 'administrator';
		}
	},
	methods: {
		navigateTo(route) {
			this.$router.push(route);
		},
		isActive(route) {
			return this.$route.path === route;
		}
	},
	setup() {
		const showSidebar = ref(true);
		const breakpoints = useBreakpoint();
		const userName = store.state.auth.username

		onMounted(() => {

		});

		return { showSidebar, breakpoints, userName };
	},
}
</script>

<style>
.active {
	background-color: #a5d1e7;
}

.p-3 {
	height: 100%;
}
</style>
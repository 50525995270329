<template>
	<VaInput v-model="filter" title="Search" placeholder="Поиск учебника" clearable class="mr-3 grow-0 basis-24" />
	<ModalAddBook @book-added="fetchData" />

	<VaDataTable class="data-table" :items="items" :filter="filter" :filter-method="customFilterMethod" :columns="columns"
		:wrapper-size="400" virtual-scroller noDataFilteredHtml="Нет элементов, соответствующих условиям поиска">
		<template #cell(actions)="{ rowIndex }">

			<VaButton preset="plain" icon="delete" class="ml-3"
				@click="deleteItemById(items[rowIndex].id)" />

		</template>
	</VaDataTable>
</template>

<script>
import { defineComponent } from "vue";
import http from '@/services/http-common';
import ModalAddBook from '@/components/ModalAddBook.vue'

export default defineComponent({
	name: 'BooksView',
	components: {
		ModalAddBook
	},
	data() {
		const items = [];
		const columns = [
			{ key: 'title', sortable: true, label: 'Название' },
			{ key: 'fileName', sortable: true, label: 'Имя файла' },
			{ key: "actions", width: 80, label: 'Действия' },
		];
		return {
			columns,
			items,
			filter: "",
		};
	},
	created() {
		this.fetchData();
		document.title = "Учебники"
	},
	methods: {
		async fetchData() {
			try {
				const response = await http.get('/books');
				this.items = response.data;
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		},
		addBookToList(book) {
			this.items.push(book);
		},
		filterExact(source) {
			if (this.filter === "") {
				return true;
			}
			return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
		},
		async deleteItemById(id) {
			try {
				const response = await http.delete(`/books/${id}`);
				if (response.status === 200) {
					this.successMessage = 'Учебник успешно удален';
					setTimeout(() => {
						this.successMessage = null;
					}, 5000);
					this.fetchData();
				}
			} catch (error) {
				console.error('Ошибка при удалении учебника:', error);
			}
		},
	},
	computed: {
		customFilterMethod() {
			return this.filterExact;
		},
	},
});
</script>

<style scoped>
.data-table {
	height: 90% !important;
}
</style>
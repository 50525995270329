<template>
	<VaButton @click="showModal = true" preset="secondary" border-color="primary" class="button-margin">Добавить
		пользователя</VaButton>

	<VaModal ref="modal" v-model="showModal" class="modal-crud" size="small" title="Добавить нового пользователя"
		@ok="addUser" @cancel="closeModal" :no-ok-close="true">
		<VaInput class="ml-3 form-element" label="Имя пользователя" v-model="username" />
		<VaInput class="ml-3 form-element" label="Пароль" v-model="password" />
		<VaInput class="ml-3 form-element" label="Email" v-model="email" />
		<VaSelect class="ml-3 form-element" label="Роль пользователя" v-model="roleID" :options="roleNames">
		</VaSelect>
		<VaTextarea v-model="description" class="ml-3 form-element" label="Описание" />
		<div v-if="hasErrors" class="alert alert-danger mt-3">
			<div v-for="(error, key) in errors" :key="key">{{ error }}</div>
		</div>
	</VaModal>
</template>

<script>
import http from '@/services/http-common';

export default {
	emits: ['user-added'],
	data() {
		return {
			showModal: false,
			username: '',
			password: '',
			email: '',
			roleID: null,
			description: '',
			roleOptions: [],
			roleNames: [],
			errors: {},
		};
	},
	created() {
		this.loadRoleOptions();
	},
	methods: {
		async loadRoleOptions() {
			try {
				const response = await http.get('/roles');
				this.roleOptions = response.data.map(role => ({
					text: role.roleName,
					value: role.id
				}));

				this.roleNames = this.roleOptions.map(option => option.text);
				this.roleID = this.roleOptions.length > 0 ? this.roleOptions[0].text : null;

			} catch (error) {
				console.error('Ошибка при загрузке списка ролей:', error);
			}
		},
		closeModal() {
			this.showModal = false;
			this.username = '';
			this.password = '';
			this.email = '';
			this.roleID = null;
			this.description = '';
			this.errors = '';
		},
		findRoleIdByText(roleText) {
			const role = this.roleOptions.find(option => option.text === roleText);
			return role ? role.value : null;
		},
		async addUser() {
			const user = {
				username: this.username,
				password: this.password,
				email: this.email,
				roleID: this.findRoleIdByText(this.roleID),
				description: this.description
			};
			if (!this.validate(user)) {
				this.showModal = true;
				return;
			}
			try {
				await http.post('/users', user);
			}
			catch (error) {
				console.error('Ошибка при добавлении пользователя:', error);
				if (error.response && error.response.status === 400 && error.response.data) {
					this.errors.adduserrequest = "Ошибка добавления пользователя. Данное имя используется активным/неактивным пользователем. Пожалуйста введите уникальное имя.";
				} else {
					this.errors.adduserrequest = 'Произошла ошибка при добавлении пользователя. Пожалуйста, попробуйте еще раз или обратитесь к администратору.';
				}
				this.showModal = true;
				return;
			}

			this.$emit('user-added');
			this.closeModal();
		},
		validate(user) {
			this.errors = {};
			const usernameRegex = /^[a-zA-Z0-9.]{4,100}$/;
			const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\d\s:])([^\s]){6,100}$/;
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

			if (!usernameRegex.test(user.username)) {
				this.errors.username = "Имя пользователя должно содержать только латинские буквы и цифры, длиной от 4 до 100 символов.";
			}
			if (!passwordRegex.test(user.password)) {
				this.errors.password = "Пароль должен содержать от 6 до 100 только латинских символов, включая минимум одну заглавную букву, одну строчную букву, одну цифру и один специальный символ.";
			}
			if (!emailRegex.test(user.email)) {
				this.errors.email = "Некорректный адрес электронной почты.";
			}
			if (!user.roleID) {
				this.errors.roleID = "Некорректное значение роли";
			}

			return Object.keys(this.errors).length === 0;
		}
	},
	computed: {
		hasErrors() {
			return Object.keys(this.errors).length > 0;
		}
	}
};
</script>
import axios from 'axios'; //библиотека axios для работы с http запросами
import { API_BASE_URL } from '@/config/apiConfig'; //базовый урл апи из конфигаруционного файла
import router from '@/router'; //маршрутизатор для навигации
import store from '@/store'; //хранилище для управления состоянием приложения

//создание экземпляра axios с заданными настройками
const http = axios.create({
    baseURL: API_BASE_URL, //установка базового урл для всех http запросов
    headers: {
        "Content-type": "application/json" //установка заголовка content type для всех запросов как json
    }
});

//axios с помощью интерцепоров перехватывает запросы или ответы до того, как они будут обработаны

//интерцептор для обработки запросов перед отправкой
http.interceptors.request.use(
    config => {
        const token = store.state.auth.accessToken; //получение токена доступа
        const expiry = new Date(store.state.auth.expiry); //получение времени истечения токена

        const currentDate = new Date(); //получение текущей даты и времени

        //проверка истечения срока действия токена
        if (expiry < currentDate) {
            store.dispatch('logout'); //выход пользователя из системы
            router.push('/login'); //перенаправление на страницу авторизации
        }

        //добавление токена в заголовок authorization, если он существует
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config; //возврат измененной конфигурации запроса
    },
    error => {
        return Promise.reject(error); //возврат ошибки, если она возникла
    }
);

//интерцептор для обработки ответов на запросы
http.interceptors.response.use(
    response => response, //простой возврат ответа, если запрос успешен
    async error => {
        // перехват ответов и обработка ошибок 401 (Unauthorized)
        if (!error.response || error.response.status !== 401) {
            return Promise.reject(error); //возврат ошибки
        }

        const originalRequest = error.config; //получение оригинального запроса, вызвавшего ошибку

        //проверка если запрос уже был попыткой повторного выполнения или запросом на обновление токена
        if (originalRequest._retry || originalRequest.url.includes('/authorize/refreshtoken')) {
            store.dispatch('logout'); //выход пользователя из системы
            router.push('/login'); //перенаправление на страницу авторизации
            return Promise.reject(error); //возврат ошибки
        }

        originalRequest._retry = true; //пометка запроса для повторной попытки

        //получение данных токенов из хранилища
        const { accessToken, refreshToken, expiry } = store.state.auth;
        const oldResult = { accessToken, refreshToken, expiry };
        //отправка запроса на обновление токенов
        const response = await http.post('/authorize/refreshtoken', oldResult);

        //сохранение новых токенов в хранилище приложения
        return store.dispatch('updateTokens', response.data)
            .then(() => {
                return new Promise((resolve) => {
                    originalRequest.headers['Authorization'] = `Bearer ${store.state.auth.accessToken}`;
                    resolve(http(originalRequest)) //повтор отправки оригинально запроса
                })
            })
            .catch(() => {
                store.dispatch('logout'); //выход пользователя из системы
                router.push('/login'); //перенаправление на страницу авторизации
                return Promise.reject(error) //возврат ошибки
            });
    }
);

// экспорт настроенного экземпляра axios 
export default http;
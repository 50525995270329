<template>
    <VaButton @click="showModal = true" preset="secondary" border-color="primary" class="button-margin">Добавить учебник
    </VaButton>

    <VaModal ref="modal" v-model="showModal" class="modal-crud" size="small" title="Добавить новый учебник"
        hide-default-actions=true>
        <VaInput class="ml-3 form-element" label="Название учебника" v-model="title" />
        <VaFileUpload v-model="basic" class="ml-3 form-element" @fileAdded="handleFileUpload" :multiple="false"
            :show-upload-button="true" type="single" />
        <div v-if="hasErrors" class="alert alert-danger mt-3">
            <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
        </div>
        <div v-if="message" class="alert alert-success mt-3">
            {{ message }}
        </div>

        <template #footer>
            <VaButton class="mr-6 mb-2" preset="secondary" hover-behavior="opacity" :hover-opacity="0.4"
                @click="closeModal">Cancel</VaButton>
            <VaButton class="mr-6 mb-2" color="primary" @click="addBook" no-ok-close=false :disabled="isLoading">OK
            </VaButton>
        </template>
    </VaModal>
</template>

<script>
import http from '@/services/http-common';

export default {
    emits: ['book-added'],
    data() {
        return {
            showModal: false,
            title: '',
            file: null,
            errors: {},
            basic: [],
            isLoading: false,
            message: null
        };
    },
    methods: {
        closeModal() {
            this.showModal = false;
            this.errors = {};
            this.title = '';
            this.file = null;
            this.basic = [];
            this.isLoading = false;
        },
        handleFileUpload(files) {
            if (files.length > 0) {
                const fileType = files[0].type;
                if (fileType !== 'application/pdf') {
                    this.errors.file = "Файл не в формате PDF.";
                } else {
                    this.file = files[0];
                    this.errors = {};
                }
            } else {
                this.file = null;
                this.errors.file = "Файл не выбран.";
            }
        },
        async addBook() {
            this.isLoading = true;
            if (!this.validate()) {
                this.isLoading = false;
                this.showModal = true;
                return;
            }
            if (!this.file) {
                this.errors.file = "Файл не выбран.";
                this.showModal = true;
                return;
            }
            this.showModal = true;
            let formData = new FormData();
            formData.append('FormFile', this.file);
            formData.append('Title', this.title);
            try {
                const response = await http.post('/books', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.status === 200 || response.status === 201) {
                    this.$emit('book-added', response.data);
                    this.message = "Учебник успешно добавлен";
                    setTimeout(() => {
                        this.closeModal();
                        this.message = null;
                    }, 3000);
                }
            } catch (error) {
                this.isLoading = false;
                if (error.response.status === 400) {
                    this.errors.title = "Ошибка добавления учебника. Данный учебник уже существует.";
                } else {
                    console.error('Error adding book:', error);
                }
            }
        },
        validate() {
            this.errors = {};
            if (!this.title) {
                this.errors.title = "Введите название учебника.";
            }
            if (!this.file) {
                this.errors.file = "Выберите файл учебника.";
            } else if (this.errors.file === "Файл не в формате PDF.") {
                return false;
            }
            return Object.keys(this.errors).length === 0;
        }
    },
    computed: {
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        }
    }
};
</script>

<style scoped>
.mr-6 {
    margin-left: 12px;
}
</style>
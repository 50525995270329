import { API_BASE_URL } from "@/config/apiConfig";

export const errorHandlerMixin = {
	methods: {
		handleError(error) {
			if (error.response) {
				switch (error.response.status) {
					case 400:
						this.errorMessage = 'Неверный логин или пароль';
						break;
					case 500:
						this.errorMessage = error.response.data.error;
						break;
					case 401:
						window.location.replace(`${API_BASE_URL}/#/login`);
				}
			} else if (error.code === 'ERR_NETWORK') {
				this.errorMessage = 'Ошибка сети. Повторите попытку позже.';
			} else {
				this.errorMessage = 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте еще раз.';
			}
		}
	}
};
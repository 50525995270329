<template>
	<VaButton icon='logout' @click="triggerLogout">Выход</VaButton>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'LogoutButton',
	methods: {
		...mapActions(['logout']),
		triggerLogout() {
			this.logout();
			this.$router.push({ name: 'Login' });
		}
	}
}
</script>
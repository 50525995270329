<template>
	<VaInput v-model="filter" title="Search" placeholder="Поиск пользователя" clearable class="mr-3 grow-0 basis-24" />
	<ModalAddUser @user-added="fetchData" />

	<VaDataTable class="data-table" :items="transformedItems" :filter="filter" :filter-method="customFilterMethod" :columns="columns"
		:wrapper-size="400" virtual-scroller noDataFilteredHtml="Нет элементов, соответствующих условиям поиска">
		<template #cell(actions)="{ rowIndex }">

			<VaButton preset="plain" icon="edit" class="ml-3" @click="openModalToEditItemById(rowIndex)" />
			<VaButton preset="plain" icon="delete" class="ml-3"
				@click="deleteItemById(transformedItems[rowIndex].id)" />

		</template>
	</VaDataTable>

	<VaModal class="modal-crud" :model-value="!!editedItem" title="Редактирование данных пользователя:" size="small"
		@ok="editItem" @cancel="resetEditedItem">
		<template v-for="key in Object.keys(editedItem)" :key="key">
			<template v-if="key === 'id'">
				<VaInput v-model="editedItem[key]" class="ml-3 form-element" label="Id пользователя" disabled />
			</template>
			<template v-else-if="key === 'username'">
				<VaInput v-model="editedItem.username" class="ml-3 form-element" label="Имя пользователя" />
			</template>
			<template v-else-if="key === 'roleName'">
				<VaSelect v-model="editedItem.roleName" class="ml-3 form-element" label="Роль пользователя"
					:options="roleNames" />
			</template>
			<template v-else-if="key === 'password'">
				<VaInput v-model="editedItem.password" class="ml-3 form-element" label="Пароль пользователя" />
			</template>
			<template v-else-if="key === 'createdAt'">
				<VaInput v-model="editedItem.createdAt" class="ml-3 form-element" label="Дата создания пользователя"
					disabled />
			</template>
			<template v-else-if="key === 'state'">
				<VaCheckbox v-model="editedItem.state" class="ml-3 form-element"
					label="Состояние пользователя (активен/неактивен)" />
			</template>
			<template v-else-if="key === 'email'">
				<VaInput v-model="editedItem.email" class="ml-3 form-element" label="Email пользователя" />
			</template>
			<template v-else-if="key === 'description'">
				<VaTextarea v-model="editedItem.description" class="ml-3 form-element" label="Описание пользователя" />
			</template>
		</template>

		<div v-if="hasErrors" class="alert alert-danger mt-3">
			<div v-for="(error, key) in errors" :key="key">{{ error }}</div>
		</div>
		<div v-if="successMessage" class="alert alert-success mt-3">
			{{ successMessage }}
		</div>
	</VaModal>
</template>

<script>
import { defineComponent } from "vue";
import http from '@/services/http-common';
import ModalAddUser from '@/components/ModalAddUser.vue'

const defaultItem = {
	username: "",
	password: "",
	email: "",
	roleID: "",
	description: ""
};

export default defineComponent({
	name: 'UsersView',
	components: {
		ModalAddUser
	},
	data() {
		const items = [];
		const columns = [
			{ key: "username", sortable: true, label: 'Имя пользователя' },
			{ key: "email", sortable: true, label: 'Email' },
			{ key: "roleName", sortable: true, label: 'Роль пользователя' },
			{ key: "createdAt", sortable: true, label: 'Дата создания' },
			{ key: "state", sortable: true, label: 'Состояние' },
			{ key: "description", sortable: true, label: 'Описание' },
			{ key: "actions", width: 80, label: 'Действия' },
		];
		return {
			columns,
			items,
			filter: "",
			roleOptions: [],
			editedItemId: null,
			editedItem: null,
			createdItem: { ...defaultItem },
			roleNames: [],
			errors: {},
			successMessage: null,
			showModal: false,
		};
	},
	created() {
		this.fetchData();
		this.loadRoleOptions();
		document.title = "Пользователи"
	},
	methods: {
		async loadRoleOptions() {
			try {
				const response = await http.get('/roles');
				this.roleOptions = response.data.map(role => ({
					text: role.roleName,
					value: role.id
				}));
				this.roleNames = this.roleOptions.map(option => option.text);
			} catch (error) {
				console.error('Ошибка при загрузке списка ролей:', error);
			}
		},
		async fetchData() {
			try {
				const response = await http.get('/users/with-rolenames');
				this.items = response.data;
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		},
		filterExact(source) {
			if (this.filter === "") {
				return true;
			}
			return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
		},
		async deleteItemById(id) {
			try {
				const response = await http.delete(`/users/${id}`);
				if (response.status === 200) {
					this.successMessage = 'Пользователь успешно удален';
					setTimeout(() => {
						this.successMessage = null;
					}, 5000);
					this.fetchData();
				}
			} catch (error) {
				console.error('Ошибка при удалении пользователя:', error);
			}
		},
		openModalToEditItemById(id) {
			this.editedItemId = id;
			this.editedItem = { ...this.items[id] };
			this.editedItem.roleName = this.transformedItems[id].roleName;
			this.editedItem.password = '';
		},
		resetEditedItem() {
			this.editedItem = null;
			this.editedItemId = null;
			this.errors = {};
		},
		async editItem() {
			try {
				const isValid = this.validateUserData(this.editedItem);

				if (!isValid) {
					return;
				}

				if (!this.editedItem.username) {
					this.errors.username = "Это имя пользователя уже используется. Пожалуйста, выберите уникальное имя.";
					return;
				}

				const userData = {
					username: this.editedItem.username,
					password: this.editedItem.password,
					email: this.editedItem.email,
					roleID: this.findRoleIdByText(this.editedItem.roleName),
					id: this.editedItem.id,
					state: this.editedItem.state,
					description: this.editedItem.description
				};

				const response = await http.put(`/users/${this.editedItem.id}`, userData);

				if (response.status === 200) {
					this.successMessage = 'Изменения успешно сохранены';
					setTimeout(() => {
						this.successMessage = null;
					}, 5000);
					this.fetchData();
				} else {
					this.errors.username = "Ошибка редактирования пользователя. Данное имя используется активным/неактивным пользователем. Пожалуйста введите уникальное имя.";
				}
			}
			catch (error) {
				console.error('Ошибка обновления пользователя:', error);
				this.errors.username = "Ошибка редактирования пользователя. Данное имя используется активным/неактивным пользователем. Пожалуйста введите уникальное имя.";
			}
		},
		validateUserData(userData) {
			this.errors = {};
			const usernameRegex = /^[a-zA-Z0-9.]{4,100}$/;
			const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\d\s:])([^\s]){6,100}$/;
			const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

			if (!usernameRegex.test(userData.username)) {
				this.errors.username = "Имя пользователя должно содержать только латинские буквы и цифры, длиной от 4 до 100 символов.";
			}
			if (!passwordRegex.test(userData.password)) {
				this.errors.password = "Пароль должен содержать от 6 до 100 только латинских символов, включая минимум одну заглавную букву, одну строчную букву, одну цифру и один специальный символ.";
			}
			if (!emailRegex.test(userData.email)) {
				this.errors.email = "Некорректный адрес электронной почты.";
			}
			if (!userData.roleID) {
				this.errors.roleID = "Некорректное значение роли";
			}

			return Object.keys(this.errors).length === 0;
		},
		findRoleIdByText(roleText) {
			const role = this.roleOptions.find(option => option.text === roleText);
			return role ? role.value : null;
		},
		formatDate(dateString) {
			if (!dateString) return null;
			return dateString.split('T')[0];
		},
	},
	computed: {
		transformedItems() {
			return this.items.map(item => {
				const roleName = this.roleOptions.find(option => option.value === item.roleID)?.text || 'Unknown';
				const formattedActivateDate = this.formatDate(item.createdAt);

				return {
					...item,
					roleName: roleName,
					createdAt: formattedActivateDate,
					state: item.state == true ? 'активен' : 'неактивен'
				};
			});
		},
		customFilterMethod() {
			return this.filterExact;
		},
		hasErrors() {
			return Object.keys(this.errors).length > 0;
		},
	},
});
</script>

<style>
.form-element {
	margin-bottom: 10px;
	width: 95%;
}

.data-table {
	height: 90% !important;
}
</style>
<template>
    <VaButton @click="showModal = true" preset="secondary" border-color="primary" class="button-margin">Добавить
        франчайзи</VaButton>

    <VaModal ref="modal" v-model="showModal" class="modal-crud" size="small" title="Добавить нового франчайзи"
        @ok="addFranchisee" @cancel="closeModal" :no-ok-close="true">
        <VaInput class="ml-3 form-element" label="Название" v-model="name" />
        <VaInput class="ml-3 form-element" label="Код активации" v-model="activationCode" readonly />
        <VaButton class="buttonGenerate" @click="generateActivationCode" preset="primary">Сгенерировать код</VaButton>
        <div v-if="hasErrors" class="alert alert-danger mt-3">
            <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
        </div>
    </VaModal>
</template>

<script>
import http from '@/services/http-common';

export default {
    emits: ['franchisee-added'],
    data() {
        return {
            showModal: false,
            name: '',
            activationCode: '',
            activateDate: new Date(),
            isActive: false,
            errors: {},
        };
    },
    methods: {
        closeModal() {
            this.showModal = false;
            this.name = '';
            this.activationCode = '';
            this.errors = '';
        },
        async addFranchisee() {
            const franchisee = {
                name: this.name,
                activationCode: this.activationCode,
                activateDate: this.activateDate.toISOString().substring(0, 10),
                isActive: this.isActive,
            };
            if (!this.validate(franchisee)) {
                this.showModal = true;
                return;
            }
            try {
                await http.post('/franchisee', franchisee);
                this.$emit('franchisee-added');
                this.closeModal();
            } catch (error) {
                console.error('Ошибка при добавлении франчайзи:', error);
                this.errors.addFranchiseeRequest = 'Ошибка добавления франчайзи. Данный код активации используется активным/неактивным франчайзи. Пожалуйста введите уникальный код активации.';
                this.showModal = true;
            }
        },
        validate(franchisee) {
            this.errors = {};
            if (!franchisee.name || franchisee.name.length < 3) {
                this.errors.name = "Название должно быть не менее 3 символов.";
            }
            if (!franchisee.activationCode) {
                this.errors.activationCode = "Введите код активации.";
            }
            return Object.keys(this.errors).length === 0;
        },
        generateActivationCode() {
            this.activationCode = `${this.randomPart()}-${this.randomPart()}-${this.randomPart()}-${this.randomPart()}`;
        },
        randomPart() {
            return String(Math.floor(1000 + Math.random() * 9000));
        },
    },
    computed: {
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        }
    }
};
</script>

<style scoped>
.buttonGenerate {
	margin-left: 16px;
}
</style>
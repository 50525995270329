<template>
    <div class="welcome-page">
        <p>
            <span>Данный сервис, предназначен для управления франчайзи. Он обеспечивает пользователей необходимыми инструментами для работы.</span><br />
            <span>Краткий обзор каждой страницы:</span>
        </p>
        <ul class="custom-list">
            <li v-if="isAdministrator"> <strong>Пользователи :</strong> Позволяет просматривать, редактировать, добавлять и удалять
                пользователей приложения Франчайзи</li>
            <li v-if="isAdministrator"> <strong>Роли :</strong> Позволяет просматривать, редактировать, добавлять, удалять роли пользователей.</li>
            <li> <strong>Учебники :</strong> Позволяет просматривать, редактировать, добавлять, удалять учебники.</li>
            <li> <strong>Франчайзи :</strong> Позволяет просматривать, редактировать, добавлять, удалять франчайзи.</li>
            <li v-if="isAdministrator"> <strong>Журнал действий :</strong> На данной странице отображены все события системы.</li>
        </ul>
    </div>
</template>

<script>
import store from '../store'

export default {
    name: 'HelloView',
    created() {
        document.title = "О франчайзи";
    },
    computed: {
		isAdministrator() {
			const userRole = store.getters.userRole;
			return userRole === 'administrator';
		}
	},
}
</script>

<style scoped>
.welcome-page {
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.welcome-page span {
    margin-left: 40px;
}

.welcome-page h5 {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.welcome-page p {
    font-size: 16px;
    margin-bottom: 15px;
}

.welcome-page .custom-list {
    padding-left: 20px;
}

.welcome-page .custom-list li {
    position: relative;
    list-style-type: none;
    margin-bottom: 10px;
}

.welcome-page .custom-list li::before {
    content: "\2022";
    position: absolute;
    left: -20px;
    color: #0088cf;
}
</style>
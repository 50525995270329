import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'
import LoginView from '../views/LoginView.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import HelloView from '@/views/HelloView.vue'
import UsersView from '@/views/UsersView.vue'
import RolesView from '@/views/RolesView.vue'
import BooksView from '@/views/BooksView.vue'
import FranchiseeView from '@/views/FranchiseeView.vue'
import JournalView from '@/views/JournalView.vue'

const availableRoles = ['administrator', 'manager'];

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: LoginView
	},
	{
		path: '/',
		name: "AdminLayout",
		component: AdminLayout,
		meta: {
			requiresAuth: true,
			role: availableRoles
		},
		children: [
			{ path: '/', component: AdminLayout, meta: { requiresAuth: true, role: availableRoles } },
			{ path: '/hello', name: 'HelloView', component: HelloView, meta: { requiresAuth: true, role: availableRoles } },
			{ path: '/users', name: 'UsersView', component: UsersView, meta: { requiresAuth: true, role: ['administrator'] } },
			{ path: '/roles', name: 'RolesView', component: RolesView, meta: { requiresAuth: true, role: ['administrator'] } },
			{ path: '/books', name: 'BooksView', component: BooksView, meta: { requiresAuth: true, role: availableRoles } },
			{ path: '/franchisee', name: 'FranchiseeView', component: FranchiseeView, meta: { requiresAuth: true, role: availableRoles } },
			{ path: '/journal', name: 'JournalView', component: JournalView, meta: { requiresAuth: true, role: ['administrator'] } },
		]
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const userRole = store.state.auth.userRole;
	const requiredRoles = to.meta.role;

	if (requiresAuth && !store.state.auth.accessToken) {
		next({ name: 'Login' });
	} else if (requiresAuth && requiredRoles && !requiredRoles.includes(userRole)) {
		next({ name: 'Login' });
	} else if (to.path === '/' && to.matched.some(record => record.path === '/')) {
		next({ name: 'HelloView' });
	} else {
		next();
	}
});

export default router

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { createVuestic } from "vuestic-ui";
import "vuestic-ui/css";
import 'bootstrap/dist/css/bootstrap.css';

const app = createApp(App);

app.config.globalProperties.$http = axios;

app.use(router).use(store).use(createVuestic(
	{
		config: {
			colors: {
				variables:
				{
					primary: "#0088cf"
				}
			}
		}
	}
)).mount('#app');

document.title = "Franchisee";
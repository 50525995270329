<template>
	<VaInput v-model="filter" title="Search" placeholder="Поиск франчайзи" clearable class="mr-3 grow-0 basis-24" />
	<ModalAddFranchisee @franchisee-added="fetchData" />

	<VaDataTable class="data-table" :items="transformedItems" :filter="filter" :filter-method="customFilterMethod" :columns="columns"
		:wrapper-size="400" virtual-scroller noDataFilteredHtml="Нет элементов, соответствующих условиям поиска">
		<template #cell(actions)="{ rowIndex }">

			<VaButton preset="plain" icon="edit" class="ml-3" @click="openModalToEditItemById(rowIndex)" />
			<VaButton preset="plain" icon="delete" class="ml-3"
				@click="deleteItemById(transformedItems[rowIndex].id)" />

		</template>
	</VaDataTable>

	<VaModal class="modal-crud" :model-value="!!editedItem" title="Редактирование данных франчайзи:" size="small"
		@ok="editItem" @cancel="resetEditedItem">
		<template v-for="key in Object.keys(editedItem)" :key="key">
			<template v-if="key === 'id'">
				<VaInput v-model="editedItem[key]" class="ml-3 form-element" label="Id франчайзи" disabled />
			</template>
			<template v-else-if="key === 'name'">
				<VaInput v-model="editedItem.name" class="ml-3 form-element" label="Название" />
			</template>
			<template v-else-if="key === 'activationCode'">
				<VaInput v-model="editedItem.activationCode" class="ml-3 form-element" label="Код активации" readonly />
				<VaButton class="buttonGenerate" @click="generateActivationCode" preset="primary">Сгенерировать код</VaButton>
			</template>
			<template v-else-if="key === 'machineCode'">
				<VaInput v-model="editedItem.machineCode" class="ml-3 form-element" label="Код машины" />
			</template>
			<template v-else-if="key === 'activateDate'">
				<div class="labelDate">ДАТА АКТИВАЦИИ</div>
				<VaDatePicker v-model="editedItem.activateDate" class="ml-3 form-element" mode="single"
					first-weekday="Monday" />
			</template>
			<template v-else-if="key === 'isActive'">
				<VaCheckbox v-model="editedItem.isActive" class="ml-3 form-element"
					label="Состояние франчайзи (активен/неактивен)" />
			</template>
		</template>

		<div v-if="hasErrors" class="alert alert-danger mt-3">
			<div v-for="(error, key) in errors" :key="key">{{ error }}</div>
		</div>
		<div v-if="successMessage" class="alert alert-success mt-3">
			{{ successMessage }}
		</div>
	</VaModal>
</template>

<script>
import { defineComponent } from "vue";
import http from '@/services/http-common';
import ModalAddFranchisee from '@/components/ModalAddFranchisee.vue'

export default defineComponent({
	name: 'FranchiseeView',
	components: {
		ModalAddFranchisee
	},
	data() {
		const items = [];
		const columns = [
			{ key: 'name', sortable: true, label: 'Название' },
			{ key: 'activationCode', sortable: true, label: 'Код активации' },
			{ key: 'machineCode', sortable: true, label: 'Код машины' },
			{ key: 'activateDate', sortable: true, label: 'Дата активации' },
			{ key: 'isActive', sortable: true, label: 'Состояние' },
			{ key: "actions", width: 80, label: 'Действия' },
		];
		return {
			columns,
			items,
			filter: "",
			editedItemId: null,
			editedItem: null,
			errors: {},
			successMessage: null,
		};
	},
	created() {
		this.fetchData();
		document.title = "Франчайзи"
	},
	methods: {
		async fetchData() {
			try {
				const response = await http.get('/franchisee');
				this.items = response.data;
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		},
		filterExact(source) {
			if (this.filter === "") {
				return true;
			}
			return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
		},
		async deleteItemById(id) {
			try {
				const response = await http.delete(`/franchisee/${id}`);
				if (response.status === 200) {
					this.successMessage = 'Франчайзи успешно удален';
					setTimeout(() => {
						this.successMessage = null;
					}, 5000);
					this.fetchData();
				}
			} catch (error) {
				console.error('Ошибка при удалении франчайзи:', error);
			}
		},
		openModalToEditItemById(id) {
			this.editedItemId = id;
			if (this.items[id]) {
				this.editedItem = { ...this.items[id] };
				console.log(this.editedItem.activateDate);
				this.editedItem.activateDate = this.editedItem.activateDate ? new Date(this.editedItem.activateDate) : null;
				console.log(this.editedItem.activateDate);
			} else {
				console.error('Item with id ' + id + ' not found');
			}
		},
		resetEditedItem() {
			this.editedItem = null;
			this.editedItemId = null;
			this.errors = {};
		},
		async editItem() {
			try {
				const isValid = this.validateFranchiseeData(this.editedItem);

				if (!isValid) {
					return;
				}

				if (!this.editedItem.activationCode) {
					this.errors.activationCode = "Введите код активации.";
					return;
				}

				let date = new Date(Date.UTC(this.editedItem.activateDate.getFullYear(), this.editedItem.activateDate.getMonth(), this.editedItem.activateDate.getDate(), 19, 0, 0));
				console.log("Corrected UTC Date:", date.toISOString());

				const franchiseeData = {
					id: this.editedItem.id,
					name: this.editedItem.name,
					activationCode: this.editedItem.activationCode,
					machineCode: this.editedItem.machineCode ? this.editedItem.machineCode: "",
					activateDate: this.editedItem.activateDate ? date.toISOString() : null,

					isActive: this.editedItem.isActive
				};

				console.log(franchiseeData);

				
				const response = await http.put(`/franchisee/${this.editedItem.id}`, franchiseeData);

				if (response.status === 200) {
					this.successMessage = 'Изменения успешно сохранены';
					setTimeout(() => {
						this.successMessage = null;
					}, 5000);
					this.fetchData();
				} else {
					this.errors.activationCode = "Ошибка редактирования франчайзи. Введите уникальный код активации.";
				}
			}
			catch (error) {
				this.errors.activationCode = "Ошибка редактирования франчайзи. Введите уникальный код активации.";
			}
		},
		validateFranchiseeData(data) {
			this.errors = {};

			if (!data.name || data.name.length < 3) {
				this.errors.name = "Название должно быть не менее 3 символов.";
			}
			return Object.keys(this.errors).length === 0;
		},
		formatDate(dateString) {
			if (!dateString) return null;
			return dateString.split('T')[0];
		},
		generateActivationCode() {
            this.editedItem.activationCode = `${this.randomPart()}-${this.randomPart()}-${this.randomPart()}-${this.randomPart()}`;
        },
		randomPart() {
            return String(Math.floor(1000 + Math.random() * 9000));
        },
	},
	computed: {
		transformedItems() {
			return this.items.map(item => {
				const formattedActivateDate = this.formatDate(item.activateDate);
				return {
					...item,
					activateDate: formattedActivateDate,
					isActive: item.isActive == true ? 'активен' : 'неактивен'
				};
			});
		},
		customFilterMethod() {
			return this.filterExact;
		},
		hasErrors() {
			return Object.keys(this.errors).length > 0;
		},
	},
});
</script>

<style scoped>
.labelDate {
	margin-left: 16px;
	margin-bottom: 5px;
	color: #0088CF;
	font-size: 10px;
	height: 16px;
	width: 487px;
	font-weight: bold;
}

.data-table {
	height: 90% !important;
}

.buttonGenerate {
	margin-left: 16px;
	margin-bottom: 10px
}
</style>
<template>
	<RouterView />
</template>

<style>
@import"https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap";@import"https://fonts.googleapis.com/icon?family=Material+Icons";

.button-margin {
  margin-bottom: 20px;
}
</style>
<template>
	<VaInput v-model="filter" title="Search" placeholder="Поиск записи в журнале" clearable
		class="mr-3 grow-0 basis-24 mb-20" />

	<VaDataTable class="data-table" :items="items" :filter="filter" :filter-method="customFilterMethod" :columns="columns"
		:wrapper-size="400" virtual-scroller noDataFilteredHtml="Нет элементов, соответствующих условиям поиска" />
</template>

<script>
import { defineComponent } from "vue";
import http from '@/services/http-common';

export default defineComponent({
	name: 'JournalView',
	components: {
	},
	data() {
		const items = [];
		const columns = [
			{ key: 'userName', sortable: true, label: 'Пользователь' },
			{ key: 'message', sortable: true, label: 'Сообщение' },
			{ key: 'timeStamp', sortable: true, label: 'Время действия' }
		];
		return {
			columns,
			items,
			filter: "",
		};
	},
	created() {
		this.fetchData();
		document.title = "Журнал действий"
	},
	methods: {
		async fetchData() {
			try {
				const response = await http.get('/journal');
				this.items = response.data;
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		},
		filterExact(source) {
			if (this.filter === "") {
				return true;
			}
			return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
		},
	},
	computed: {
		customFilterMethod() {
			return this.filterExact;
		},
	},
});
</script>

<style scoped>
.mb-20 {
	margin-bottom: 20px;
}

.data-table {
	height: 90% !important;
}
</style>
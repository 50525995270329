<template>
	<div class="container mt-1">
		<div class="row justify-content-center">
			<div class="col-md-4">
				<div class="card shadow">
					<h5 class="va-h6 text-center">Управление франчайзи</h5>
					<div class="card-body">
						<VaForm ref="myForm" class="login-form mb-3 text-start">
							<VaInput label="Имя пользователя" class="mb-3" v-model="credentials.username"
								placeholder="Введите ваше имя пользователя" :rules="[(v) => Boolean(v) || 'Введите имя пользователя',
								(v) => v.length >= 4 || 'Имя пользователя должно содержать не менее 4 символов']">
							</VaInput>
							<VaInput label="Пароль" class="mb-3" v-model="credentials.password" type="password"
								placeholder="Введите ваш пароль" :rules="[(v) => Boolean(v) || 'Введите пароль',
								(v) => v.length >= 6 || 'Пароль должен содержать не менее 6 символов']">
							</VaInput>
							<va-button @click="login" color="primary">Войти</va-button>
						</VaForm>
						<div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import { errorHandlerMixin } from '@/mixins/errorHandlerMixin';
import { API_BASE_URL } from '@/config/apiConfig';

export default {
	mixins: [errorHandlerMixin],
	created() {
		document.title = "Авторизация пользователя";
	},
	data() {
		return {
			credentials: {
				username: '',
				password: ''
			},
			errorMessage: '',
		};
	},
	methods: {
		async login() {
			this.clearErrors();
			const isValid = await this.validateForm();
			if (!isValid) return;

			await this.performLogin();
		},
		clearErrors() {
			this.errorMessage = '';
		},
		async validateForm() {
			const isValid = await this.$refs.myForm.validate();
			if (!isValid) {
				return;
			}
			return isValid;
		},
		async performLogin() {
			this.errorMessage = '';
			try {
				const response = await this.$http.post(`${API_BASE_URL}/authorize/login`, this.credentials);
				this.$store.dispatch('login', response.data);
				this.$store.dispatch('saveState');
				this.$router.replace({ name: 'AdminLayout' });
			} catch (error) {
				this.handleError(error);
			}
		}
	}
};
</script>

<style scoped>
.va-h6 {
	color: rgb(83, 83, 83);
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 65vh;
}

.card {
	width: 100%;
	max-width: 500px;
}

.login-form {
	display: flex;
	flex-direction: column;
}

.alert-danger ul {
	padding-left: 0px;
}
</style>